@import "@scss/common.scss";

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #cccccc !important;
  font-size: 14px !important;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #cccccc !important;
  font-size: 14px !important;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #cccccc !important;
  font-size: 14px !important;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #cccccc !important;
  font-size: 14px !important;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;

  textarea {
    resize: none;
  }
}

html {
  user-select: none;

  body {
    margin: 0;
    width: 100%;
    height: 100%;

    #root {
      @include font-normal;
      width: 100%;
      min-width: 1380px;
      height: 100%;
      overflow: auto;
      position: relative;
      color: #4a4a4a;
      font-family: MicrosoftYaHei;
    }
  }
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
ol,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.app-container {
  width: 100%;
  min-height: 100%;
  @include background-img;
  padding-top: 50px;
  padding-bottom: 120px;

  // 展示背景图（登录、注册、忘记密码，企业实名认证、个人实名认证）
  &-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // 界面内容宽度
  .container {
    width: 100%;
    padding: 0 90px;
  }
}

/* ant 样式重写 */
html body {
  // 输入框
  .ant-input {
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(220, 223, 230, 1);
    @include font-normal;

    &:hover {
      border-color: #2d66b0;
    }

    &:focus {
      border-color: #2d66b0;
      box-shadow: none;
    }
  }

  .ant-select-selection {
    box-sizing: border-box;
    min-height: 40px;
    line-height: 40px !important;
    border-radius: 4px;
    border: 1px solid rgba(220, 223, 230, 1);
    @include font-normal;

    &:hover {
      border-color: #2d66b0;
    }

    &:focus {
      border-color: #2d66b0;
      box-shadow: none;
    }

    .ant-select-selection__rendered {
      height: 40px;
      line-height: 38px !important;
    }
  }

  // 多选框
  .ant-checkbox-wrapper {
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: #2d66b0;
      left: 50%;
    }

    .ant-checkbox-inner::after {
      left: 26%;
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border: none;
        background: linear-gradient(
          90deg,
          rgba(44, 100, 173, 0.75) 0%,
          #2d66b0 100%
        );
      }

      &:after {
        border-color: #2d66b0;
      }
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #d9d9d9;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #2d66b0;
  }

  // 确认弹窗
  .ant-modal-confirm {
    .ant-modal-content {
      .ant-modal-body {
        .ant-modal-confirm-body-wrapper {
          .ant-modal-confirm-body {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .anticon {
              float: none;
            }

            .anticon-check-circle {
              color: #2d66b0;
            }

            .ant-modal-confirm-title {
              font-size: 14px;
              font-weight: normal;
              color: #4a4a4a;
            }
          }

          .ant-modal-confirm-btns {
            display: flex;
            justify-content: flex-end;
            float: none;

            .ant-btn {
              background: rgba(242, 242, 242, 1);
              border-radius: 4px;
              border: 1px solid rgba(228, 231, 237, 1);
              font-size: 14px;
              color: #333333;

              &:hover {
                border-color: #e4e7ed;
                color: #333333;
              }

              &-primary {
                background: linear-gradient(
                  90deg,
                  rgba(44, 100, 173, 0.75) 0%,
                  #2d66b0 100%
                );
                color: #ffffff;
                border: none;

                &:hover {
                  border: none;
                  background: linear-gradient(
                    90deg,
                    rgba(44, 100, 173, 0.75) 0%,
                    #2d66b0 100%
                  );
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }

  // 按钮
  .ant-btn-primary {
    background: linear-gradient(
      90deg,
      rgba(44, 100, 173, 0.75) 0%,
      #2d66b0 100%
    );
    border: none;

    &:focus {
      background: linear-gradient(
        90deg,
        rgba(44, 100, 173, 0.75) 0%,
        #2d66b0 100%
      );
      border: none;
    }

    &:active {
      background: linear-gradient(
        90deg,
        rgba(44, 100, 173, 0.75) 0%,
        #2d66b0 100%
      );
      border: none;
    }

    &:hover {
      background: linear-gradient(
        90deg,
        rgba(44, 100, 173, 0.75) 0%,
        #2d66b0 100%
      );
      border: none;
    }
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #2d66b0;
  }
  .ant-tabs-ink-bar {
    background-color: #2d66b0;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #2d66b0;
  }
}

// 全局加载
.app-loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-spin-dot-item {
    background: #2d66b0;
  }
}

.app-idou-loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-spin-dot-item {
    background: rgb(0, 186, 118);
  }
}

.ant-radio-wrapper {
  span.ant-radio + * {
    padding-left: 4px;
    padding-right: 4px;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #2d66b0 !important;
  }

  &-checked {
    span.ant-radio + * {
      color: #2d66b0;
    }

    .ant-radio-input:focus + .ant-radio-inner {
      border-color: #2d66b0;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: #2d66b0;

      &:after {
        background-color: #2d66b0;
      }
    }
  }

  &:hover {
    .ant-radio-inner {
      border-color: #2d66b0;
    }
  }

  .ant-radio {
    &:hover {
      .ant-radio-inner {
        border-color: #2d66b0;
      }
    }
  }
}

.ant-spin-dot-item {
  background: #2d66b0;
}

// 表格
.ant-table-wrapper {
  table {
    table-layout: fixed;
  }

  .ant-table-content {
    .ant-table-thead {
      tr {
        th {
          background: #f8f9fb;
          color: #4a4a4a;
          @include font-normal;
          height: 62px;
          border-bottom: none !important;
          font-weight: normal;
          padding: 11px;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          height: 60px;
          @include font-normal;
          color: #7a7a82;
          border-bottom: none;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:nth-child(2n) {
          background: #f8f9fb;

          &:hover {
            td {
              background: #f8f9fb;
            }
          }
        }

        &:hover {
          td {
            background: #ffffff;
          }
        }
      }
    }
  }

  .ant-table-placeholder {
    display: none;
  }
}

// 抽屉
.ant-drawer-content-wrapper {
  .ant-drawer-content {
    .ant-drawer-header {
      padding: 0;
      border: none;
    }

    .ant-drawer-body {
      padding: 0;
      border: none;
    }
  }
}

.ant-tooltip-inner {
  white-space: nowrap;
}

div
  .ant-calendar-picker:hover
  .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #2d66b0;
}

.page-content {
  width: 1200px;
  display: inline-block;
}

//submenu
.ant-dropdown-link {
  position: relative;
  top: 1px;
  vertical-align: bottom;
  display: inline-block;
  padding: 0 10px;
  margin: 0 10px;
  color: #000000a6;
  border-bottom: 2px solid transparent;
  transition: all 0.2s ease;
  cursor: pointer;
}

.ant-dropdown-link:hover {
  color: #2d66b0;
  border-bottom: 2px solid #2d66b0;
}

.ant-dropdown-menu-item:hover {
  background-color: #fff !important;
  color: #2d66b0;
}

.ant-dropdown-menu {
  top: 15px;
  padding-right: 5px !important;
}

.ant-dropdown-menu::before {
  content: "";
  position: absolute;
  left: 10px;
  top: -18px;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
}

.ant-dropdown-menu-item-selected {
  background-color: #fff !important;
  color: #2d66b0 !important;
}

.ant-table-selection-column {
  .ant-checkbox-wrapper {
    position: relative;
    top: 10px;
  }
}
.ant-btn-danger {
  color: #fff !important;
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12) !important;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045) !important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045) !important;
}

.ant-menu-horizontal {
  border-bottom: none !important;
}
