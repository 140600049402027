@keyframes zFadeIn {
  0% {
    opacity: 0;
    transform: scale3d(0.2, 0.2, 0.2);
  }

  50% {
    opacity: 0.5;
    transform: scale3d(0.6, 0.6, 0.6);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
.zFadeIn {
  animation-duration: 0.75s;
  animation-timing-function:linear;
  animation-name: zFadeIn;
}