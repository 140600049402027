@mixin font-normal {
  font-size: 14px;
}

@mixin font-small {
  font-size: 12px;
}

@mixin font-big {
  font-size: 16px;
}

// 公用的样式表
@mixin background-img {
  width: 100%;
  min-height: 100%;
  position: relative;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@mixin account-content {
  margin: 200px 0;
  padding: 60px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 19px 0px rgba(0, 0, 0, 0.4);
  border-radius: 4px;

  .account-tabs {
    display: flex;
    justify-content: center;
    align-items: center;

    .type-content-name {
      height: 50px;
      width: 230px;
      line-height: 50px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(151, 151, 151, 1);
      color: #77777d;
      text-align: center;
      cursor: pointer;
      // @include font-big;
    }

    .type-content-name:first-child {
      border-radius: 4px 0px 0px 4px;
      border-right: none;
    }

    .type-content-name:last-child {
      border-left: none;
      border-radius: 0px 4px 4px 0px;
    }

    .active-account {
      background: linear-gradient(
        90deg,
        rgba(44, 100, 173, 0.75) 0%,
        #2d66b0 100%
      );
      border: none;
      color: #ffffff;
    }
  }

  .button-next {
    margin-top: 30px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: linear-gradient(
      90deg,
      rgba(44, 100, 173, 0.75) 0%,
      #2d66b0 100%
    );
    border-radius: 4px;
    text-align: center;
    outline: none;
    border: none;
    color: #ffffff;
    @include font-normal;

    .ant-spin-dot-item {
      background-color: #ffffff;
    }
  }
  .disable-button {
    background: #cccccc;
    color: #666666;
  }
  .account-agreement {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    .account-check {
      margin-right: 10px;
    }
    .agreement-text {
      margin: 0;
      position: relative;
      top: 1px;

      span {
        color: #2d66b0;
      }
    }
  }

  .account-success {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .icon-success {
      width: 100px;
      height: 100px;
      margin-top: 40px;
    }

    .success-title {
      font-size: 20px;
      color: #333333;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 15px;
    }

    .success-text {
      text-align: center;
      color: #999999;
      // @include font-normal;
      margin-bottom: 40px;
    }

    .success-buttons {
      display: flex;
      justify-content: center;
      align-items: center;

      .success-button {
        width: 200px;
        height: 40px;
        line-height: 40px;
        background: rgba(230, 230, 230, 1);
        border-radius: 4px;
        border: none;
        display: block;
        text-align: center;
        color: #4a4a4a;
      }

      .button-orange {
        background: linear-gradient(
          90deg,
          rgba(44, 100, 173, 0.75) 0%,
          #2d66b0 100%
        );
        color: #ffffff;
        margin-right: 30px;
      }
    }
  }

  .account-other-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    color: #2d66b0;
    // @include font-normal;

    .account-other-link {
      margin-right: 5px;
      margin-left: 5px;
      color: #2d66b0;
    }
  }
}
